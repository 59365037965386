.section-programation-a {
    
    .period .bradius {
        background-color: var(--programation-color);
        border-radius: 15px;
        
    }

    .clock-time {
        justify-content: flex-end;
        align-items: flex-start;
    }
    
    .period {
        min-width: 100%;
        .event {
            margin: 30px 0px;

            .speaker {
                margin: 20px 40px 20px 0px;
            }
        }

        .event:not(:last-child) {
            border-bottom: 2px solid var(--detail-light-color);
        }
    }


    @media (max-width: 680px) {
        .clock-time {
            justify-content: flex-start;
            align-items: center;
        }

        .col-md-11 .flex-row {
            flex-direction: column !important;
        }
    }

}
