
.group-title {
    display: inline-block;
    background-color: rgba(184, 184, 184, 0.3);
    border-radius: 20px;
    padding: 10px 30px;
    font-weight: bold;
    font-size: 15px;
    margin: 30px;
}

.collapse-items {
    border-radius: 10px;
    border: solid 1.5px var(--border-color);
    width: 100%;

    .collapse-item {
        border-bottom: solid 1.5px var(--border-color);
        &:last-child {
            border-bottom: none;
        }
    }

    .collapse-content {
        border-top: solid 1.5px var(--border-color);
        .row  {
            height: 100%;
            padding: 10px 36px;
            
            &:first-child {
                padding-top: 20px;
            }

            &:last-child {
                padding-bottom: 20px;
            }
        }
    }

    .collapse-header {
        padding: 0 36px;
        min-height: 80px;
        display: flex;
        cursor: pointer;
        .module {
            min-width: 160px;
            font-size: 16px;
            font-weight: 600;
            margin-right: 0;
            display: flex;
            align-items: center;
        }

        .title {
            flex: 1;
            font-size: 13.5px;
            display: flex;
            align-items: center;
        }

        .icon {
            margin-left: 45px;
            font-size: 30px;
            display: flex;
            align-items: center;
            i {
                transition: all .2s;
                display: inline-block;
                transform: rotate(180deg);
            }
        }

        &.collapsed {
            .icon {
                i {
                    display: inline-block;
                    transform: rotate(0);
                }
            }
        }
    }


    @media (max-width: 680px) {

        .collapse-header {
            padding: 10px 20px;
            .module {
                font-size: 14px;
                min-width: 120px;
            }
            .icon {
                margin-left: 10px;
            }
        }

        .collapse-content {
            .row {
                padding: 5px 20px;
                display: flex;
                .col-11 {
                    flex: 1;
                }
            }
        }
    }
    
}