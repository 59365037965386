
html, body {
    width: 100%;
    height: 100%;
}

html {
    overflow: auto;
    overflow: overlay;
}

body.swal2-shown {    
    overflow: hidden !important;
    height: 100% !important;
    padding-right: 0 !important;
}

body.swal2-toast-shown {
    overflow: auto !important;
    overflow: overlay !important;
    height: 100% !important;
    padding-right: 0 !important;
}

#page-header {
    background: transparent;
}

.block-header {
    padding: 14px 20px 0px;
}

.block-header-full {
    padding: 14px 20px;
}

.content-heading {
    padding-top: 0;
}

#page-container.sidebar-inverse #sidebar {
    background: #2c3643;
}

#sidebar .content-header.content-header-fullrow {
    background: #323d4c;
}

#sidebar .content-side.content-side-full {
    padding: 0 18px;
}

.user-dropdown button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    height: 40px;
}

.user-dropdown button .img-avatar {
    width:32px; 
    height: 32px;
    margin-right: 5px;
}


.icon {
    font-family: 'tdpremium' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.animated {
    animation-fill-mode: initial;
}