.section-features-b {
    .card-block {
        padding: 20px;
        border-radius: 16.2px;
        background-color: #161616;
        width: 320px;
        height: 320px;
    }

    .dtd-features {
        .card-block {
            padding: 7px;
            border-radius: 0px;
            color: #000;
            background-color: var(--green);
            width: 340px;
            max-height: 340px;
        }
    }

    @media (max-width: 680px) {
    }

}
