

.section-content-k {

    @media (max-width: 680px) {

        .content-left {
            padding-left: 16px !important;
            padding-right: 16px !important;
            margin-bottom: 20px;
        }

        .content-right {
            padding-left: 16px !important;
            padding-right: 16px !important;
        }
    }
}
