.section-content-o {
    iframe {
        width: 100%;
        height: 600px;
    }

    .different-iframe {
        width: 757px;
        height: 429px;
    }

    @media (max-width: 680px) {
        iframe {
            height: 225px;
        }

        .different-iframe {
            width: 100%;
            height: 225px;
        }

        .credit_card_logos {
            display: none !important;
        }
    }
    @media (min-width: 1920px) {
        .different-iframe {
            width: 1057px !important;
            height: 629px !important;
        }
    }
}