.section-features-e {

    .features {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        
        .feature {
            background-color: var(--feature-block);
            min-height: 310px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            
        }
        
        .right-feature {
            border-top-left-radius: 0px ;
            border-bottom-left-radius: 0px ;
        }
        .left-feature {
            border-top-right-radius: 0px ;
            border-bottom-right-radius: 0px ;
        }
        .center-feature {
            min-height: 450px;
            background-color: var(--feature-block-center) !important;
            &:before {
                content: "\ea0e";
                position: absolute;
                top: calc(50% - 60px);
                font-size: 60px;
                color: var(--magenta);
                left: -30px;
                font-family: remixicon;
            }
            &:after {
                content: "\ea0e";
                position: absolute;
                top: calc(50% - 60px);
                font-size: 60px;
                color: var(--magenta);
                right: -30px;
                font-family: remixicon;
                z-index: 99;
            }
        }
    }

    @media (max-width: 680px) {
        .features {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            border-radius: 15px;
        }  

        .right-feature {
            border-bottom-right-radius: 15px !important;
            border-bottom-left-radius: 15px !important;
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;
        }

        .left-feature {
            border-bottom-left-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
            border-top-left-radius: 15px !important;
            border-top-right-radius: 15px !important;
        }

        .center-feature {
            min-height: 450px;

            &:before {
                position: absolute;
                top: calc(0% - 30px) !important;
                line-height: 1;
                left: calc(50% - 30px) !important;
            }
            &:after {
                position: absolute;
                top: calc(100% - 30px) !important;
                line-height: 1;
                right: calc(50% - 30px) !important;
            }
        }
    }
}