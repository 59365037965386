.section-bonus-a {
    .bonus-logo {
        width: 95px;
        margin: 0px 20px;
    }

    .bonus-image {
        background-image: var(--bg-notebook);
        background-size: cover;
        height: 513px;
        width: 100%;
        margin-top: 50px;
    }
    
    @media (max-width: 680px) {
        .bonus-image {
            height: 167px;
        }

        .bonus-logo {
            width: 100%;
            margin: 0px;
        }
    }
}
