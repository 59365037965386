.section-featured-a {
    position: relative;
   
    .position-unset {
        position: unset;
    }

    img {
        position: absolute;
        top: 100px;
        left: 0;
    }

    @media (max-width: 680px) {
        img {
            position: unset;
            width: 100%;
            border-radius: 5px;
        }
    }
}
