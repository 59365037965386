@font-face {
  font-family: 'tdpremium';
  src:  url('fonts/tdpremium.eot?r7cpro');
  src:  url('fonts/tdpremium.eot?r7cpro#iefix') format('embedded-opentype'),
    url('fonts/tdpremium.ttf?r7cpro') format('truetype'),
    url('fonts/tdpremium.woff?r7cpro') format('woff'),
    url('fonts/tdpremium.svg?r7cpro#tdpremium') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tdpremium' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-course:before {
  content: "\e917";
}
.icon-arrow-right-line:before {
  content: "\e900";
}
.icon-award-fill:before {
  content: "\e905";
}
.icon-bank-card-2-line:before {
  content: "\e906";
}
.icon-barcode-fill:before {
  content: "\e907";
}
.icon-chat-1-fill:before {
  content: "\e908";
}
.icon-check-line:before {
  content: "\e909";
}
.icon-lock-fill:before {
  content: "\e90a";
}
.icon-mail-fill:before {
  content: "\e90b";
}
.icon-mail-send-line:before {
  content: "\e90c";
}
.icon-phone-fill:before {
  content: "\e90d";
}
.icon-shield-flash-fill:before {
  content: "\e90e";
}
.icon-star-fill:before {
  content: "\e90f";
}
.icon-thumb-up-fill1:before {
  content: "\e910";
}
.icon-time-line:before {
  content: "\e911";
}
.icon-timer-line:before {
  content: "\e912";
}
.icon-user-fill:before {
  content: "\e913";
}
.icon-whatsapp-fill:before {
  content: "\e914";
}
.icon-alarm-warning-fill:before {
  content: "\e915";
}
.icon-arrow-left-line:before {
  content: "\e916";
}
.icon-thumb-down-line:before {
  content: "\e901";
}
.icon-thumb-up-fill:before {
  content: "\e903";
}
.icon-thumb-up-line:before {
  content: "\e902";
}
.icon-thumb-down-fill:before {
  content: "\e904";
}
