.section-header {    
    position: relative;

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;
    }

    header {        

        .brand {
            img {
                height: 40px;
            }
        }

        .btn-hero {
            margin-left: 80px;
        }
        
        .content-header {
            height: 78px;
            background-color: var(--menu-bar-color);
        }
        
        .menu {
            display: inline;
    
            .btn {
                font-size: 15px;
                margin: 0 15px;
                &:hover {
                    background-color: rgba(255, 255, 255, 0.2);
                    border-color: transparent;
                    box-shadow: none;
                }
            }
        }
    }

    .bg-image {
        height: 100vh;
        .sub-title {
            font-weight: normal;
        }
    }

    .bg-video {
        height: 100vh;

        iframe {
            height: 100%;
            width: 100%;
        }
    }

    @media (max-width: 680px) {
    }
}

.money-week-page {
    .section-header {    
        position: relative;
        background-color: var(--primary-color);

        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: -1;
        }
    
        header {        
    
            .brand {
                img {
                    height: 40px;
                }
            }
    
            .btn-hero {
                margin-left: 80px;
            }
            
            .content-header {
                height: 100%;
                background-color: var(--menu-bar-color);
            }
            
            .menu {
                display: inline;
                a::after {
                    content: '';
                    display: block;
                    width: 0;
                    height: 2px;
                    background: var(--primary-hover-color);
                    transition: width .3s;
                }
                a:hover::after {
                    width: 100%;
                    //transition: width .3s;
                }
        
                .btn {
                    font-size: 15px;
                    margin: 0 15px;
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.2);
                        border-color: transparent;
                        box-shadow: none;
                    }
                }
            }
        }
        .bg-image {
            height: 100vh;
            .sub-title {
                font-weight: normal;
            }
        }
    
        .bg-video {
            height: 100vh;
    
            iframe {
                height: 100%;
                width: 100%;
            }
        }
    
        @media (max-width: 680px) {
        }
    }
}

