.section-advantages-a {
    .important-block {
        background-color: var('--bg-dark-color');
        padding: 30px;
        border-radius: 25px;
        margin-bottom: 50px;
        margin-top: 40px;
    }

    .bonus-checklist {
        text-align: center;
        max-width: 700px;
    }

    .checklist-bonus {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .icon-bonus-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;   
    }

    @media (max-width: 680px) {
        .checklist-bonus {
            flex-direction: column;
            justify-content: space-between;  
        }
        
        .icon-bonus-list {
            flex-direction: column;   
            align-items: center;
        }

        .icon-bonus {
            width: 100% !important;
            margin: 10px 0px;
            display: flex;
            justify-content: center;
            text-align: center;
        }
    }

    .border-bottom {
        border-bottom: 2px solid #202020 !important;

        &:last-child {
            border-bottom: none !important;
        }
    }

    .icon-bonus {
        width: 210px;
        border-radius: 20px;
        background-color: #202020;
        padding: 20px 15px;
    }
}