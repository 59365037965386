.section-logos-a {

    .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 4px;
    }
    .cls-1 {
        fill: var(--logos-bg) !important;
    }
    @media (max-width: 680px) {
        .logo {
            max-width: 175px;
            max-height: 65px;
            margin: 15px auto;
        }
    }
}
.money-week-page {
    .section-logos-a {
        margin-top: 100px;
    }
}