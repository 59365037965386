.section-header-d {
    .arrow {
        text-align: center;
        margin: 8% 0;
        position: absolute;
        bottom: 50px;
        left: calc(50% - 25px);
        margin: 0px;
    }

    iframe {
        border: 3px solid #fff;
        border-radius: 3px;
    }

    .bounce {
        -moz-animation: bounce 2s infinite;
        -webkit-animation: bounce 2s infinite;
        animation: bounce 2s infinite;
    }


    .section-header .bg-image {
        height: calc(100vh + 10px);
    }

    @media (max-width: 680px) {
        .arrow {
            bottom: 70px;
        }

        .h-100 {
            height: unset !important;
        }

        iframe {
            height: 230px;
        }

        .w-100 {
            width: unset !important;
        }
    }
}