.section-content-d {
    background-color: #161616;
    background-size: cover;
    background-repeat: no-repeat;

    .content {
        color: #fff;

        .description {
            color: var(--secondary-color);
            font-weight: normal;
        } 

        h2 {
            color: #fff;
        }

        i {
            margin-top: 40px;
            min-width: 58px;
            height: 58px;                
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            border-radius: 50%;
            position: relative;
            margin-right: 20px;
            display: inline-flex;
            margin-bottom: 26px;
            color: #fff;
            background-color: var(--secondary-color);
        }
    }
}