.section-content-l {
    .important-block {
        background-color: #2d2d2d;
        padding: 30px;
        border-radius: 25px;
        margin-bottom: 50px;
        margin-top: 40px;
    }

    .bonus-checklist {
        text-align: center;
        max-width: 700px;
    }

    .bonus-card-block {
        border-radius: 36px;
        box-shadow: 30px 22px 70px 0 rgba(35, 81, 245, 0.2);
        background-color: #2351f5;
        padding: 0;
        background-image: url("../../assets/images/meu-negocio/bitmap.png");
        background-size: cover;
        
        .card-title {
            padding: 40px 30px;
            text-align: center;
            border-radius: 36px;
            background-color: #315dff; 
            
            h3 {
                margin-bottom: 0;
            }
        }
        
        .card-body {
            border-radius: 36px;
            padding: 80px 100px;
        }

    }

    .checklist-bonus {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .icon-bonus-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;   
    }

    @media (max-width: 680px) {
        .checklist-bonus {
            flex-direction: column;
            justify-content: space-between;  
        }
        
        .icon-bonus-list {
            flex-direction: column;   
            align-items: center;
        }

        .icon-bonus {
            width: 100% !important;
            margin: 10px 0px;
            display: flex;
            justify-content: center;
            text-align: center;
        }
    }

    .border-bottom {
        border-bottom: 2px solid #202020 !important;

        &:last-child {
            border-bottom: none !important;
        }
    }

    .icon-bonus {
        width: 210px;
        border-radius: 20px;
        background-color: #202020;
        padding: 20px 15px;
    }
}