.section-content-e {
    position: relative;

    .topic {
        padding-bottom: 50px;
    }

    .text-columns {
        column-count: 2;
        column-gap: 120px;
    }

    .content-right {
        position: initial;
    }

    .right-image {
        position: absolute;       
        right: 0;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        width: 440px;
        height: 820px;

        img {
            width: 100%;
            height: 100%;
        }
        .orator {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.2);
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;

            .info {
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 30px;

                h3, h4, small {
                    color: #fff;
                }
            }
        }
    }

    @media (max-width: 680px) {

        .text-columns {
            column-count: 1;
            column-gap: 0;
        }

        .content-right { 
            display: none;
        }
        
    }
}