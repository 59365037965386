html {
    //position: fixed;
    overflow: initial;
    //overflow-x: hidden;
}

body, #page-container {
    overflow-x: hidden !important;
}

.btn,
.btn.btn-hero, 
.btn.btn-hero.btn-lg {
    font-weight: 500;
    border-radius: 10px;
}

.btn.btn-hero.btn-lg {
    padding: 16px  32px;
}

// .wpp-block {
//     display: none;
// }

.money-week-page {
    .btn-block-option {
        color: var(--dark-color) !important;
        i {
            color: var(--dark-color) !important;
        }
    }

    #modal-terms {
        .btn-block-option {
            color: var(--dark-color) !important;
            i {
                color: var(--light-color) !important;
            }
        }
    }
    
    .btn-hero {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
            transition: all 0.3s;
            border: 1px solid rgba(0,0,89,0.5);
            transform: scale(1.2,1.2);
            border-radius: 5px;
        }
        &:hover::after {
            opacity: 1;
            transform: scale(1,1);
        }
    }
}


section.content {
    padding-top: 100px;
    padding-bottom: 100px;
    height: 100%;
   

    @media (max-width: 680px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
    
.section-title {
    margin-bottom: 60px;
    h2 {
        margin-bottom: 11px;
    }
    small {
        display: block;
    }
}

.duotone {
    background-color: unset;
    background-color: #fff;
    display: flex;
    flex: 1 1 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    transition: 0.6s;
}

.duotone:hover {
    transition: 0.4s;
    background-color: var(--base-duotone);
    &:before {
        background-color: var(--foreground-duotone);
    }
    img {
        filter: unset;
    }
}

.duotone img {
    flex: 1 0 100%;
    height: 100%;
    max-width: 100%;
    mix-blend-mode: var(--bg-blend);
    object-fit: cover;
    opacity: var(--opacity);
    position: relative;
    width: 100%;
  }

  .duotone::before {
    // background-color: var(--foreground-duotone);
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    // mix-blend-mode: var(--fg-blend); 
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  
  @supports ( mix-blend-mode: var(--fg-blend) ) {
    .duotone::before {
        mix-blend-mode: var(--fg-blend);
    }
  }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
        background-color: var(--primary-dark-color) !important;
    }

.hoverGreen:hover {
    background-color: #122215 !important;
    transition: 0.8s;
}

.hoverBlue:hover {
    background-color: #111D25 !important;
    transition: 0.8s;
}

.hoverOrange:hover {
    background-color: #251A0E !important;
    transition: 0.8s;
}

.hoverRed:hover {
    background-color: #200E11 !important;
    transition: 0.8s;
}

.hoverPurple:hover {
    background-color: #170F1E !important;
    transition: 0.8s;
}

.hoverYellow:hover {
    background-color: #201F10 !important;
    transition: 0.8s;
}

.main-color {
    color: var(--primary-color);
}

.row-to-column {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.exit-popup-modal {
    height: 3px;
    width: 100%;
    background-color: transparent;
    position: fixed;
    top: 0;
    z-index: 1049;
}

.modal-backdrop.fade {
    transition: none;
} 

#modal-checkout, #modal-exit-popup {

    .modal-dialog {
        max-width: 600px;
    }

    .modal-content {
        background-image: var(--bg-cta);
        background-size: cover;
        color: var(--light-color);
        background-repeat: no-repeat;
        
        h3 {
            color: var(--light-color);
        }

        .block-header {
            background: transparent !important;
        }

        .invalid-feedback {
            color: #fffb45;
        }

        .block-content {
            padding: 0px 70px 1px;
        }

        .modal-footer {
            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
        }

        input {
            background: transparent;
            border: none;
            border-bottom: 1px solid #fff;
            border-radius: 0 !important;
            color: var(--light-color);

            &::placeholder { 
                color: var(--light-color);
            }
        }

        .modal-footer {
            border: none;

            button {
                background-color: #fff !important;
                color: #000;
                border-radius: 10px;
                background-color: var(--secondary-color);
                background-image: linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
                color: var(--light-color);
                font-weight: normal;
                background-size: 300% 100%;
                transition: all .4s ease-in-out;
            }:hover {
                background-position: 100% 0;
                transition: all .4s ease-in-out;
                transform: scale(1.02);
            }
        }
    }

}

#modal-exit-popup {
    .modal-content {
        background-image: var(--bg-cta-blue) !important;
        background-repeat: no-repeat;
        background-color: #000;
    }
}

.modal-backdrop {
    transition: opacity 1s ease-out;
    pointer-events: none !important;
}

.modal-backdrop.show {
    opacity: 0.5;
    background-color: #000;
    pointer-events: none !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: Work Sans, sans-serif;
}

purchase-notification {
    .purchase-notification {
        position: fixed;
        bottom: 20px;
        left: 20px;
        height: 70px;
        border-radius: 15px;
        box-shadow: 0 9px 26px 0 rgba(219, 219, 219, 0.21);
        background-color: #ffffff;        
        padding: 0 20px;
        display: flex;
        z-index: 999999;

        .user-image {
            margin-right: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            .user-image-bg {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: #000000;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 40px;
                }
            }
        }

        .user-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .user-desc {
            font-size: 13px;
            color: #9b9b9b;
        }
        .user-name {
            font-size: 15px;
            font-weight: 600;
        }
    }
    
    @media (max-width: 680px) {
        width: 100%;
        display: flex;
        position: fixed;
        justify-content: center;
        align-items: center;
        bottom: 10px;

        .purchase-notification {
            position: initial;
            bottom: initial;
            left: initial;
            .user-image { 
                display: none;
            }
        }        
    }
}

.wpp {
    display: none !important;
}

.wpp, .wpp-mobile {
    position: fixed;
    bottom: 33px;
    right: 25px;
    background-color: #25d366;
    color: #fff;
    padding: 25px 36px !important;
    height: 55px !important;
    display: flex;
    font-weight: normal;
    justify-content: center;
    align-items: center;
    
    &:hover {
        background-color: #12a849;
        color: #fff;
    }

    i {
        font-size: 30px;
        margin-left: 13px;
    }
}

.wpp-mobile {
    padding: 10px !important;
    // display: none;
    border-radius: 10px !important;
    font-size: 20px !important;
    box-shadow: 2px 1px 22px -10px #000;
    position: fixed;
    z-index: 99;
    justify-content: center;
    align-items: center;
    height: 65px !important;
    width: 65px !important;
    i {
        font-size: 30px;
        margin-left: 0 !important;
    }
}

.new-modal {
    border-radius: 5px;

    .modal-dialog {
        max-width: 520px;
    }

    .modal-content {
        color: var(--light-color);
        background-color: #252525;
        border-radius: 15px;
        
        h3 {
            color: var(--light-color);
        }

        .block-header {
            background: transparent !important;
        }

        .invalid-feedback {
            color: #e6733e;
        }

        .block-content {
            padding: 0px 70px 1px;
        }

        .modal-footer {
            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
        }

        input {
            background-color: #1d1d1d;
            border: none;
            border-radius: 10px !important;
            color: var(--light-color);

            &::placeholder { 
                color: var(--light-color);
            }
        }

        .modal-footer {
            border: none;

            button {
                background-color: var(--modal-button-color) !important;
                border-radius: 7.5px;
                padding: 16px 50px;
                color: var(--light-color);
                font-weight: normal;
                margin: 0px 50px;
            }:hover {
                transition: all .4s ease-in-out;
                transform: scale(1.02);
            }
        }
    }

}

.new-modal2 {
    border-radius: 5px;

    .modal-dialog {
        max-width: 520px;
    }

    .modal-content {
        color: var(--light-color);
        background-color: #252525;
        border-radius: 15px;
        
        h3 {
            color: var(--light-color);
        }

        .block-header {
            background: transparent !important;
        }

        .invalid-feedback {
            color: var(--secondary-color);
        }

        .block-content {
            padding: 0px 70px 1px;
        }

        .modal-footer {
            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
        }

        input {
            background-color: #1d1d1d;
            border: none;
            border-radius: 10px !important;
            color: var(--light-color);

            &::placeholder { 
                color: var(--light-color);
            }
        }

        .modal-footer {
            border: none;

            button {
                background-color: var(--modal-button-color) !important;
                border-radius: 0px;
                padding: 16px 50px;
                color: var(--light-color);
                font-weight: normal;
                margin: 0px 50px;
            }:hover {
                transition: all .4s ease-in-out;
                transform: scale(1.02);
            }
        }
    }

}

.dtd-modal {
    border-radius: 5px;

    .modal-dialog {
        max-width: 520px;
    }

    .modal-content {
        color: #141414;
        background-color: var(--green);
        // border-radius: 15px;
        
        h3 {
            color: #141414;
        }
        
        .btn-block-option {
            color: #141414 !important;
        }

        .block-header {
            background: transparent !important;
        }

        .invalid-feedback {
            color: #e77960;
        }

        .block-content {
            padding: 0px 70px 1px;
        }

        .modal-footer {
            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none; 
            margin: 0; 
        }

        input {
            background-color:  #FFFFFF;
            border: none;
            border-radius: 10px !important;
            color: #141414;

            &::placeholder { 
                color: #141414;
            }
        }

        .modal-footer {
            border: none;

            button {
                background-color: #e77960 !important;
                border-radius: 0px;
                padding: 16px 50px;
                color: var(--light-color);
                font-weight: normal;
                margin: 0px 50px;
            }:hover {
                transition: all .4s ease-in-out;
                transform: scale(1.02);
            }
        }
    }
}

.money-week-modal {
    border-radius: 5px;

    .modal-dialog {
        max-width: 520px;
    }

    .modal-content {
        color: var(--primary-dark-color);
        background-color: var(--bg-modal);
        // border-radius: 15px;
        
        h3 {
            color: var(--primary-dark-color);
        }
        
        .btn-block-option {
            color: var(--primary-dark-color) !important;
        }

        .block-header {
            background: transparent !important;
        }

        .invalid-feedback {
            color: #e28111;
        }

        .block-content {
            padding: 0px 60px 0px;
        }

        .modal-footer {
            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none; 
            margin: 0; 
        }

        input {
            background-color:  #FFFFFF;
            border: none;
            border-radius: 10px !important;
            color: #141414;

            &::placeholder { 
                color: #141414;
            }
        }

        .modal-footer {
            border: none;

            button {
                color: #f9f9f9;
                position: relative;
                font-weight: normal;
                margin-right: 30px;
                margin-bottom: 40px;
                letter-spacing: 1.67px;
                background-color: #3a00a0;
                font-family: "Be Vietnam", sans-serif !important;
                padding: 23px 36px;
                border-radius: 5px;
            }:hover {
                transition: all .4s ease-in-out;
                transform: scale(1.02);
            }
        }
    }

}

.dig-conf-modal {
    border-radius: 5px;

    .modal-dialog {
        max-width: 520px;
    }

    .modal-content {
        color: var(--light-color);
        background-color: var(--bg-modal);
        // border-radius: 15px;
        
        h3 {
            color: var(--light-color);
        }
        
        .btn-block-option {
            color: var(--light-color) !important;
        }

        .block-header {
            background: transparent !important;
        }

        .invalid-feedback {
            color: #e28111;
        }

        .block-content {
            padding: 0px 60px 0px;
        }

        .modal-footer {
            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none; 
            margin: 0; 
        }

        input {
            background-color:  #FFFFFF;
            border: none;
            border-radius: 10px !important;
            color: #141414;

            &::placeholder { 
                color: #141414;
            }
        }

        .modal-footer {
            border: none;

            button {
                background-color: var(--modal-button) !important;
                border-radius: 0px;
                padding: 16px 50px;
                color: var(--dark-color);
                font-weight: normal;
                margin: 0px 50px;
            }:hover {
                transition: all .4s ease-in-out;
                transform: scale(1.02);
            }
        }
    }

}

.modal-qvm {
    border-radius: 5px;

    .modal-dialog {
        max-width: 520px;
    }

    .modal-content {
        color: var(--light-color);
        background-color: var(--azul);
        border-radius: 15px;
        
        h3 {
            color: var(--light-color);
        }

        .block-header {
            background: transparent !important;
        }

        .invalid-feedback {
            color: var(--secondary-color);
        }

        .block-content {
            padding: 0px 70px 1px;
        }

        .modal-footer {
            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
        }

        input {
            background-color: #1a1799;
            border: none;
            border-radius: 10px !important;
            color: var(--light-color);

            &::placeholder { 
                color: var(--light-color);
            }
        }

        .modal-footer {
            border: none;

            button {
                background-color: var(--laranja) !important;
                border-radius: 10px;
                padding: 16px 50px;
                color: var(--light-color);
                font-weight: normal;
                margin: 0px 50px;
            }:hover {
                transition: all .4s ease-in-out;
                transform: scale(1.02);
            }
        }
    }
}

@media (max-width: 680px) {
    .dig-conf-modal {
        .block-content {
            padding: 0px 20px 0px !important;
        }
    }

    .modal {
        z-index: 9050 !important;
    }

    body {
        overflow-x: hidden !important;
    }

    .wpp {
        display: none;
    }

    .wpp-mobile {
        display: flex !important;
    }

    .row-to-column {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}