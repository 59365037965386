.section-list-b {
    .testimony-card {
        padding: 43px;
        border-radius: 7px;
        min-height: 560px;

        img {
            min-width: 49px;
            height: 49px;                
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            border-radius: 50%;
            position: relative;
            margin-right: 20px;
            display: inline-flex;
            margin-bottom: 26px;
        }
    }

    .testimony-card:hover {
        background-color: var(--hover-color);
        color: #fff !important;
        transition: 0.1s;
        
        h5, small {
            color: #fff !important;
        }

    }
}