.section-features-a {

    .features {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
        .feature {
            background-color: #fafafa;
            width: 350px;
            max-width: 350px;
            min-width: 350px;
            margin-bottom: 40px;
        }
    }

    @media (max-width: 680px) {
        .features {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }   
    }
}