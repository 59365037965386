.section-mentors-b {


    .mentors {
        display: flex;
        flex-direction: row;
    
        img {
            max-width: 100%;
        }
    }

    .mentor-right {
        flex-direction: row-reverse;
    }

    @media (max-width: 680px) {
        .mentors {
            flex-direction: column;
        }

        .mentor-right {
            flex-direction: column;
        }
    }

}
