.section-local-a {

    .places {
        background-color: var(--primary-color);
        color: var(--light-color);
        max-width: 270px;

        .selected {
            background-color: var(--bg-dark-color);
            h3 {
                color: var(--secondary-color);
            }
        }

        .option:hover {
            cursor: pointer;
            background-color: var(--bg-dark-color);
            h3 {
                color: var(--secondary-color);
            }
        }
    }

    .image1, .image2, .image3 {
        background-repeat: no-repeat;
        background-position: center;
    }
    
    .image1 {
        background-image: var(--local-1);
    }
    
    .image2 {
        background-image: var(--local-2); 
    }
    
    .image3 {
        background-image: var(--local-3); 
    }

    @media (max-width: 680px) {

        .image1, .image2, .image3 {
            background-size: cover;
        }
        
        .places {
            margin-bottom: 150px;
            max-width: 190px;
        }
    }
}