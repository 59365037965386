.section-streaming-a {

    .video-view {
        height: 100vh !important;
    }

    .non-video-view {
        height: 60vh !important;
    }
    .auth-button {
        position: relative;
        color: var(--light-color);
        font-weight: normal;
        margin-right: 30px;
        margin-bottom: 40px;
        letter-spacing: 1.67px;
        background-color: #3a00a0;
        font-family: 'Be Vietnam', sans-serif !important;
        padding: 23px 36px;
        border-radius: 5px;
        &:hover {
            background-color: transparent;
            color: #3a00a0 !important;
        }
    }
    .actual-talk-video {

        .bg-video {
            width: 92vw;
            max-width: 1280px;
            margin: -80vh auto 60px auto;
            padding: 20px;
            display: flex;

            .w-100 {
                margin: 0px;
            }
            
            .video-side {
                display: flex;
                border-radius: 2px;
                flex-direction: column;
                width: 70%;
                height: 100%;
                position: sticky;
                top: 0;
                background: var(--bg-light-color);
                
                .video-embed {
                    display: block;
                    width: 100%;
                    padding-bottom: 56.25%;
                    height: 0;
                    position: relative;
                    overflow: hidden;
                }
                
            }
            
            .comment-side {
                border-top-right-radius: 2px;
                background: var(--bg-light-color);
                width: 30%;
                padding: 20px;
                height: 730px;
                overflow-y: auto;

                iframe {
                    width: 100% !important;
                }
            }
        }
        .row {
            padding: 0px;
            border-radius: 2px;
        } 
    }

    .speakers-block {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .speaker-item {
            width: 100%;

            small {
                margin-bottom: 30px;
                display: block;
            }
        }
    }

    .px-80 {
        padding-left: 80px;
        padding-right: 80px;
    }
    
    .video-wrapper {
        height: 100vh;
        width: 100%;
        background-image: var(--header);
        background-size: cover;
        background-position: center center;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;   
    }

    .streaming-block {
        margin: 0px 80px 100px 80px;
        & > div:not(.videos) {
            margin-top: 100px;
        }
    }

    .video-link {
        position: relative;
        min-width: 300px;
        width: calc((100% / 4) - 10px);
        display: block;
        margin: 0 5px 20px 5px;
        transition: .2s;

        &:hover {
            cursor: pointer;
            transform: scale(1.1);
            z-index: 3;
        }
        
        .video-block {
            position: relative;
            width: 100%;
            padding-bottom: 60%;
            height: 0;
            
            .video-bg {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 0;
                overflow: hidden;
                
                img {
                    position: absolute;
                    width: 100%;
                    height: auto;
                    top: -40px;
                    z-index: 0;
                }
            }
            
            .video-top {
                padding: 10px;
                text-transform: uppercase;
                display: flex;
                font-size: 9px;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                z-index: 2;
                flex-direction: row;
                align-content: center;
                justify-content: space-between;
                color: var(--light-color);
                
                .video-time {
                    padding: 4px 5px;
                    background: var(--bg-dark-button);
                    border-radius: 3px;
                    font-size: 11px;
                    line-height: 1;
                    letter-spacing: 1.1px;
                    display: inline-table;
                    color: var(--light-color)
                }

                .live-chip {
                    color: var(--light-color) !important;
                    background: var(--red) !important;
                }
            
                .available-chip {
                    color: var(--light-color) !important;
                    background: var(--green) !important;
                }
            
            }
            
            .video-footer {
                font-size: 11px;
                z-index: 2;
                text-transform: uppercase;
                color: var(--light-color);
                padding: 10px;
                flex-direction: row;
                align-content: center;
                justify-content: space-between;
                position: absolute;
                bottom: 0;
                left: 0;
            }

            .video-content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 30px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #FFF;
                font-size: 15px;
                z-index: 2;
            }

            &:before, &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                background: #040e20;
                width: 100%;
                height: 100%;
                z-index: 1;
                opacity: .3;
            }
        }
    }

    @media (max-width: 680px) {
        .bg-image {
            height: 100vh;
            .sub-title {
                font-weight: normal;
            }
        }

        .actual-talk-video {
            .bg-video {
                margin: -80vh auto 60px auto;

                .video-side, .comment-side {
                    width: 100%;
                    padding: 0px;
                    position: relative;
                }

                .video-side {
                    padding-bottom: 20px;
                    max-height: unset;
                    height: auto;
                }
            }
        }

        .video-wrapper {
            flex-direction: column;
        }

        .streaming-block {
            margin: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            & > div:not(.videos) {
                margin-top: 40px;
            }

            .videos {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }

}
