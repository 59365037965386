.section-calendar-a {

    .nav-link {
        background-color: transparent;
        border: none;
        box-shadow: none;

        &.active {
            border-radius: 14px;
            box-shadow: 0 6px 32px 0 rgba(158, 121, 255, 0.39);
            background-image: linear-gradient(65deg, #642cff, #8d5cff);
            padding: 20px 34px;
            h4, small {
                color: var(--light-color) !important;
            }
        }
    }

    @media (max-width: 680px) {
    }

}