.section-content-m {
    .complete-progress-bar {
        margin-bottom: 60px;
        max-width: 831px;
        width: 100%;
        height: 36px;
        border-radius: 7.5px;
        background-color: #2e2e2e;
        
        .progress-bar {
            height: 36px;
            border-radius: 7.5px;
            background-color: #f24e21;
        }
    }
}