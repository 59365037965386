.section-timer-a {
    .dig-conf-timer {
        justify-content: space-evenly !important;
        align-items: center !important;
        height: 100% !important;
        margin: 0px auto !important;
        max-width: 1140px !important;

        .number, .label {
            color: var(--light-color) !important;
        }

        .timer-space {
            color: var(--light-color);
        }

        .timer-number {
            background-color: #5100e1 !important;
        }
    }
    
    .money-week-timer {
        justify-content: space-evenly !important;
        align-items: center !important;
        height: 100% !important;
        margin: 0px auto !important;
        max-width: 1140px !important;

        .number, .label {
            color: var(--primary-dark-color) !important;
        }

        .timer-space {
            color: var(--primary-dark-color);
        }

        .timer-number {
            background-color: var(--primary-color) !important;
        }
    }

    .timer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .timer-text {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;

            h4, h5, small {
                margin-bottom: 0px;
            }
        }

        .timer-button {
            margin-left: 40px;
            .btn {
                padding: 18px 30px;
            }
        }

        .timer-numbers {
            display: flex;
            height: 55px;
            padding: 5px 15px;
            border-radius: 10px;
            justify-content: center;
            align-items: center;

            .timer-number {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                padding: 8px 8px;
                background-color: rgba(255, 255, 255, 0.2);
                border-radius: 10px;
                display: block;
                min-width: 61px;
                
                .label {
                    text-align: center;
                    font-size: 10px;
                }

                .number {
                    font-size: 24px;
                    line-height: 1;
                }
            }

            .timer-space {
                margin: 5px 12px;
                font-size: 11px;
            }

        }

    }

    @media (max-width: 680px) {

        position: relative !important;
        margin: 0 !important;
        top: 0 !important;
        border-radius: 0 !important;
        height: 140px !important;

        .timer {
            flex-direction: column !important;
        }

        // .timer-text {
        //     display: none !important;
        // }

        .timer-text2 {
            display: none !important;
        }


        .timer-button {
            display: none;
        }
    }
}



