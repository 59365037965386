.section-header-c {
    cursor: initial;
    .made-by {
        position: absolute;
        bottom: 10px;
        left: 33px;
    }

    @media (max-width: 680px) {
        .image-header {
            display: none;
        }

        .content-top {
            height: calc(100vh - 70px)!important;

        }

        .made-by {
            left: 0px !important;
            svg {
                width: 100%;
            }
        }
    }
}
