.section-features-c {
    .features {
        .feature {
            border-radius: 20px;
            background-color: #1a1a1a;
            padding: 15px;
            transition: 0.8s;
            margin-bottom: 20px;
        }
    }
    
    .lightFeature {
        background-color: var(--bg-light-color) !important;
    }

    @media (max-width: 680px) {
        .features {
            > div {
                flex-direction: column;
            }

            .mr-20 {
                margin-right: unset !important;
            }
        }   
    }
}