

.section-list-a {

    ul {
        margin: 0;
        padding: 0;
        max-width: 440px;
        margin-left: 90px;
        li {
            display: flex;
            margin-bottom: 50px;

            i {
                min-width: 49px;
                height: 49px;                
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                border-radius: 50%;
                position: relative;
                margin-right: 20px;
            }
            

            h4 {
                margin-bottom: 12px;
            }
        }
    }
   

    @media (max-width: 680px) {

        ul {
            margin-left: 0;
        }
        
    }
}