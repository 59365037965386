.section-content-f {
    position: relative;   
    
    small, h3 {
        color: #fff;
        display: block;
        margin-bottom: 40px;
    }

    h3 {
        font-weight: normal;
    }

    video {
        height: 450px;
    }

    h2 {
        color: var(--primary-color);
    }

    @media (max-width: 680px) {

        background-image: none !important;
        height: auto !important;
       
    }
}