.section-header-a {
    .mobile-menu {
        display: none;
    }

    .mobile-button {
        display: none;
    }

    .made-by {
        position: absolute;
        bottom: 40px;
        right: 20px;
    }

    .dig-conf {
        .made-by {
            position: absolute;
            bottom: 80px;
            right: unset;
        }   
    }

    .money-week {
        .made-by {
            position: unset;
            bottom: unset;
            right: unset;
        }   
    }

    .side-arrow {
        color: #fff;
        position: absolute;
        bottom: 20px;
        left: 20px;
    }
    
    .header-dates {
        position: absolute;
        bottom: 20px;

        div {
            margin-right: 50px;
        }
    }

    @media (max-width: 680px) {
        .content-header-section-links {
            display: none;
        }
        .mobile-menu {
            display: block;

            #menuToggle {
                display: block;
                position: relative;
                z-index: 1;
                -webkit-user-select: none;
                user-select: none;
            }

            #menuToggle input {
                display: block;
                width: 40px;
                height: 32px;
                position: absolute;
                top: -7px;
                left: -5px;
                cursor: pointer;
                
                opacity: 0; /* hide this */
                z-index: 2; /* and place it over the hamburger */
                
                -webkit-touch-callout: none;
            }


            #menuToggle span {
                display: block;
                width: 33px;
                height: 4px;
                margin-bottom: 5px;
                position: relative;
                
                background: var(--burguer-menu-color);
                border-radius: 3px;
                
                z-index: 1;
                
                transform-origin: 4px 0px;
                
                transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                            background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                            opacity 0.55s ease;
            }

            #menuToggle span:first-child {
                transform-origin: 0% 0%;
            }

            #menuToggle span:nth-last-child(2) {
                transform-origin: 0% 100%;
            }

            #menuToggle input:checked ~ span {
                opacity: 1;
                transform: rotate(45deg) translate(-2px, -1px);
                background: var(--burguer-menu-color2);
            }

            #menuToggle input:checked ~ span:nth-last-child(3) {
                opacity: 0;
                transform: rotate(0deg) scale(0.2, 0.2);
            }

            #menuToggle input:checked ~ span:nth-last-child(2) {
                transform: rotate(-45deg) translate(0, -1px);
            }

            #menu {
                position: absolute;
                width: 300px;
                margin: -51px 0 0 -255px;
                padding: 40px;
                padding-top: 125px;
                
                background: var(--mobile-menu-color);
                list-style-type: none;
                -webkit-font-smoothing: antialiased;
                /* to stop flickering of text in safari */
                
                transform-origin: 0% 0%;
                transform: translate(100%, 0);
                
                transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
            }


            #menuToggle input:checked ~ ul {
                transform: none;
            }
        }

        .image-header {
            display: none;
        }
        
        .dtd-header {
            .image-header {
                display: block;
            }

            .content-top {
                height: calc(100vh)!important;
            }
        }

        .dig-conf {
            .image-header {
                display: block;
            }

            .made-by {
                position: absolute;
                bottom: 0px;
                right: 0px;
            } 

            .content-top {
                height: calc(100vh)!important;
            }

            .ri-arrow-down-line {
                bottom: 5px !important;
            }
        }

        .money-week {
            .image-header {
                display: block;
            }

            .made-by {
                position: absolute;
                bottom: 0px;
                right: 0px;
            } 

            .content-top {
                height: calc(100vh)!important;
            }

            .ri-arrow-down-line {
                bottom: 5px !important;
            }
        }

        .header-dates {
            bottom: 90px;

            div {
                margin-right: 30px;

                h4 {
                    font-size: 20px !important;
                }
            }
        }

        .made-by {
            position: absolute;
            bottom: 40px;
            right: 10px;
        }

        .mobile-button {
            width: 100%;
            display: flex;
            position: fixed;
            justify-content: center;
            align-items: center;
            bottom: 0;
            z-index: 999;
            background-image: linear-gradient(0deg, black, transparent);
            padding-bottom: 20px;
        }

        .content-top {
            height: calc(100vh - 70px)!important;
        }
    }

    @media (max-width: 680px) and (max-height: 640px) {

        .dig-conf {
            .made-by {
                position: absolute;
                bottom: 0px;
            }   
        }

        .ri-arrow-down-line {
            color: #fff;
            position: absolute;
            bottom: 10px;
            left: 20px;
        }
    }

    @media (max-width: 320px) {
        .dig-conf {
            .made-by {
                bottom: 50px !important;
            }
        }
    }
}
