.section-testimony-a {
    .testimonials {
        .owl-stage {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        iframe {
            width: 320px; 
            height: 565px; 
        }
        
        .owl-video-wrapper {
            margin: 0px auto;
        }

        .owl-item {
            opacity: 0;
            -webkit-backface-visibility: hidden;
            -webkit-transform: translateZ(0) scale(1.0, 1.0);
            
            .active {
                max-width: 320px;
            }

            .cloned {
                max-width: 320px;
            }
        }

        .owl-next, .owl-prev {
            width: 35px;
            height: 60px;
            line-height: 35px;
            border-radius: 3px;
            position: absolute;
            top: 40%;
            font-size: 29px;
            color: #fff;
            text-align: center;
            background-color: rgba(216, 216, 216, 0.116);
            border: none;
            font-weight: normal;
            z-index: 1;

            &:hover {
                color: var(--primary-color);
                background-color: #230909;
            }
        }

        .item-video {
            opacity: 0.7;
            transition: .4s ease all;
            transform: scale(.8);
            display: flex;
            justify-content: center;
            align-items: center;

            iframe {
                border-radius: 16px;
            }

        }

        .active {
            opacity: 1;
        }

        .active.center {
            margin: 0 20px;
            
            .item-video {
                opacity: 1 !important;
                transform: scale(1) !important;
            }
        }


        .active .owl-video-tn {
            opacity:1;
        }

        .owl-video-tn {
            height: 500px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: 16px;
        }

        .owl-controls {
            position: absolute;
            margin-top: 320px;
        }

        .owl-prev {
            left: 60px;
        }

        .owl-next {
            right: 60px;
        }
    }

    @media(max-width:1000px) {
        // .item, .item-video {
        //     margin: 0;
        //     transform: scale(.9);
        // }
    }

    @media (max-width: 680px) {

        iframe {
            width: 260px !important; 
            height: 459px !important; 
        }

        .owl-prev {
            left: 12px !important;
        }

        .owl-next {
            right: 12px !important;
        }

        .active.center {
            margin: 0 !important;
        }

        .owl-next, .owl-prev {
            width: 25px !important;
            height: 55px !important;
            line-height: 25px !important;
        }
    }
}
