.section-topics-b {

    .topic-line {
        border-bottom: 1px solid #202020;
    }

    .topic-line:last-of-type {
        border-bottom: none;
    }

    @media (max-width: 680px) {
    }

    .topics-hovered:hover {
        h3, small, i {
            color: var(--primary-color) !important;
            transition: 0.8s;
        }
    }
}
