.section-mentors-c {

    .mentor-block {
        display: flex;
        flex-direction: column;
    }
    
    .mentor-image {
        position: relative;
        margin-bottom: 20px;

        img {
            width: 100%;
        }

        .mentor-info {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 20px;
            background-image: linear-gradient(0deg, black, transparent);
            width: 100%;

            h5, p, small {
                margin: 0;
            }
        }
    }


    @media (max-width: 680px) {
        .mentor-block {
            padding: 0px;
        }

    }

}
