.section-mentors-a {

    .event-block {
        display: flex;
        flex-direction: row;
        align-items: center;

        .card-image {
            position: relative;
            
            .card-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                display: flex;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
                justify-content: flex-end;
                align-items: start;
                flex-direction: column;
                padding: 12px;
            }
        }
    }

    .dtd {
      .event-block {
        display: flex;
        flex-direction: row;
        align-items: center;

        .card-image {
            position: relative;
            
            .card-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                display: flex;
                background-image: none;
                justify-content: flex-end;
                align-items: start;
                flex-direction: column;
                padding: 12px;
            }
        }
    }  
    }
    @media (max-width: 680px) {
        .event-block {
            flex-direction: column;
        }

        
        .card-image {
            min-height: 220px;
            
            .card-overlay {
                top: 130px !important;
                display: block !important;

                h5 {
                    font-size: 16px !important;
                }
            }
        }
    }

}
