.section-form-a {
    #modal-terms {
        .block-header {
            position: sticky;
            top: 0;
        }

        .modal-dialog {
            max-width: 100%;
            margin: 0;

            p {
                font-size: 16px;
            }
        }
    }
    
    .form-block {
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
        margin: 30px 0px;


        .input-group .form-control, .select2, .select2-container--default .select2-selection--single {
            min-width: 300px !important;
            max-width: 300px !important;
            background-color: #efeff3;
            color: #6c757d !important;
            border: none;
            border-radius: 8px !important;
            font-family: Raleway, sans-serif !important;
        }
        
        .input-group input:focus, .select-wrapper select:focus {
            background-color: #e7e2ff !important;

        }

        .select2-container--default .select2-selection--single .select2-selection__placeholder {
            color: #6c757d !important;

        }
        
        .select2-container--default .select2-selection--single .select2-selection__rendered {
            color: #6c757d !important;
        }
        
        div > input, div > td-select {
            margin-right: 16px;
        }

        .two-input-group {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
    }

    .money-week-form {
        .select2-selection--multiple {
            border: none;
            background: transparent;
        }

        .select2-container--default .select2-selection--multiple .select2-selection__choice {
            background-color: #10ff62 !important;
            color: var(--dark-color) !important;
        }
        .select2-selection__choice__remove {
            color: var(--dark-color) !important;
        }

        label {
            color: #fff;
            line-height: 2;
        }
        #terms-link {
            color: var(--primary-color);
            &:hover {
                color: var(--light-color);
            }
        }

        .form-block {
            .select2-selection__arrow {
                color: #6c757d;
            }

            .input-group .form-control, .select2 {
                color: #6c757d;
                border: 1px solid #979797;
            }

            .input-group .form-control, .select2, .select2-container--default .select2-selection--single {
                min-width: 300px !important;
                max-width: 300px !important;
                background-color: transparent !important;
                border-radius: 5px !important;
                font-family: Be Vietnam, sans-serif !important;
            }

            .select2-container--default .select2-selection--single .select2-selection__placeholder {
                color: #6c757d !important;
    
            }
            
            .select2-container--default .select2-selection--single .select2-selection__rendered {
                color: #6c757d !important;
            }
            
            .input-group input:focus, .select-wrapper input[type=select]:focus {
                color: #fff !important;
                border: 1px solid #fff !important;
                background-color: transparent !important;
            }
    
            input:not(:placeholder-shown), .select2[value=""] {
                color: var(--primary-color) !important;
                border: 1px solid var(--primary-color) !important;

                .select2-selection__rendered, .select2-selection__arrow {
                    color: var(--primary-color) !important;
                    // border: 1px solid var(--primary-color) !important;
                }
            }
            
            .select2-selection--multiple {
                .input-group input:focus, .select-wrapper input[type=select]:focus {
                    color: transparent !important;
                    border: none !important;
                    background-color: transparent !important;
                }

                input:not(:placeholder-shown), .select2[value=""] {
                    color: var(--primary-color) !important;
                    border: none !important;
    
                    .select2-selection__rendered, .select2-selection__arrow {
                        color: var(--primary-color) !important;
                        // border: 1px solid var(--primary-color) !important;
                    }
                }
            }
            
            .select2-container--default .select2-search--inline .select2-search__field {
                padding: 9px 0px;
                border: none !important;
            }
            div > input, div > td-select {
                margin-right: 16px;
            }

        }
    }

    .money-week-form2 {
        .select2-selection--multiple {
            border: none;
            background: #fff;
        }

        .select2-container--default .select2-selection--multiple .select2-selection__choice {
            background-color: #10ff62 !important;
            color: var(--dark-color) !important;
        }
        .select2-selection__choice__remove {
            color: var(--dark-color) !important;
        }

        label {
            color: var(--dark);
            line-height: 2;
        }

        #terms-link {
            color: var(--bg-modal);
            &:hover {
                color: var(--primary-dark-color);
                text-decoration: underline;
                transition: 0.4s;
            }
        }

        .form-block {
            margin-top: -50px;
            align-items: center !important;
            .select2-selection__arrow {
                color: var(--dark-color);
            }

            .input-group .form-control, .select2 {
                color: var(--dark-color);
                border: 1px solid transparent;
            }

            .input-group .form-control, .select2, .select2-container--default .select2-selection--single {
                min-width: 300px !important;
                max-width: 300px !important;
                background-color: #fff !important;
                border-radius: 5px !important;
                font-family: Be Vietnam, sans-serif !important;
            }

            .select2-container--default .select2-selection--single .select2-selection__placeholder {
                color: var(--dark-color) !important;
    
            }
            
            .select2-container--default .select2-selection--single .select2-selection__rendered {
                color: var(--dark-color) !important;
            }
            
            .input-group input:focus, .select-wrapper input[type=select]:focus {
                color: var(--dark-color) !important;
                border: 1px solid var(--dark-color) !important;
                background-color: #fff !important;
            }
    
            input:not(:placeholder-shown), .select2[value=""] {
                color: var(--primary-dark-color) !important;
                border: 1px solid var(--primary-dark-color) !important;

                .select2-selection__rendered, .select2-selection__arrow {
                    color: var(--primary-dark-color) !important;
                    // border: 1px solid var(--primary-color) !important;
                }
            }
            
            .select2-selection--multiple {
                .input-group input:focus, .select-wrapper input[type=select]:focus {
                    color: transparent !important;
                    border: none !important;
                    background-color: transparent !important;
                }

                input:not(:placeholder-shown), .select2[value=""] {
                    color: var(--primary-dark-color) !important;
                    border: none !important;
    
                    .select2-selection__rendered, .select2-selection__arrow {
                        color: var(--primary-dark-color) !important;
                        // border: 1px solid var(--primary-color) !important;
                    }
                }
            }
            
            .select2-container--default .select2-search--inline .select2-search__field {
                padding: 9px 0px;
                border: none !important;
            }
            div > input, div > td-select {
                margin-right: 16px;
            }

        }
    }
    
    @media (max-width: 680px) {
        .form-block {
            display: block !important;
            margin: 0px auto;
            flex-direction: column;

            .input-group .form-control, .select2 {
                min-width: 100% !important;
                max-width: 100% !important;
            }

            .two-input-group {
                display: block;
                flex-direction: column;
            }

            div > input, div > td-select {
                margin-right: unset;
            }
        }
        
        .money-week-form {
            .form-block {
                display: block !important;
                margin: 0px auto;
                flex-direction: column;
    
                .input-group .form-control, .select2 {
                    min-width: 100% !important;
                    max-width: 100% !important;
                }
    
                .two-input-group {
                    display: block;
                    flex-direction: column;
                }
    
                div > input, div > td-select {
                    margin-right: unset;
                }
            }
        }   

        .money-week-form2 {
            .form-block {
                display: block !important;
                margin: 0px auto;
                flex-direction: column;
    
                .input-group .form-control, .select2 {
                    min-width: 100% !important;
                    max-width: 100% !important;
                }
    
                .two-input-group {
                    display: block;
                    flex-direction: column;
                }
    
                div > input, div > td-select {
                    margin-right: unset;
                }
            }
        }   
    }
}