.section-content-n {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;

    
    h5 {
        font-weight: 600;
    }

    h2, h3, small, h5, h4 {
        color: #fff;
    }

    .top-topics {
        padding-top: 60px; 
        padding-bottom: 60px; 
    }

    .checkout-info {
        h2 {
            font-weight: normal;
        }

        .pricing {
            padding-top: 25px;
        }

        .description-text {
            font-weight: normal !important;
        }

        button {
            margin-bottom: 25px;
        }
    }

    .safety-info {
        padding-top: 90px;

        h4 {
            margin-bottom: 5px;
        }

        i {
            font-size: 38px;
        }
    }

    .footer-topics {
        padding-top: 60px;

        small {
            text-align: left;
        }
    }


    @media (max-width: 680px) {

        text-align: center;
    }
    
    
}