
.section-timer-b {
    .timer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .timer-numbers {
            
            display: flex;
            padding: 5px 15px;
            border-radius: 10px;
            justify-content: center;
            align-self: center;
            
            .timer-number {
                display: flex;
                flex-direction: column;
                align-items: center;
                min-width: 110px;
                .label {
                    font-size: 18px;
                    margin-top: 5px;
                }

                .number {
                    font-size: 71px;
                    line-height: 1;
                }
            }

            .timer-space {
                margin: 5px 40px;
                font-size: 36px
            }
        }
    }


    @media (max-width: 680px) {
        .timer {
            .timer-numbers {
            
                display: flex;
                padding: 5px 15px;
                border-radius: 10px;
                justify-content: center;
                align-self: center;

                
                .timer-number {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    min-width: 60px;

                    .label {
                        font-size: 10px;
                        margin-top: 5px;
                    }
    
                    .number {
                        font-size: 40px;
                        line-height: 1;
                    }
                }
    
                .timer-space {
                    margin: 5px 10px;
                    font-size: 20px
                }
            }
        }
    }
}