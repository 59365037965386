.section-events-a {

    .event-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .card-image {
            position: relative;
            
            .card-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(0, 0, 0, 0.5);
                &:hover {
                    background-color: rgba(0, 0, 0, 0.2);
                    transition: 0.8s;
                }
            }

            &:hover {
                transform: scale(1.04);
                transition: 0.8s;
            }
        }


    }
    @media (max-width: 680px) {
        .event-block {
            flex-direction: column;
        }
    }

}
